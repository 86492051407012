
import { defineComponent, reactive } from "vue";
import { sk, tahapanBelajar, tryoutPemantapan, listTopDA } from "./dashboard-admin";
import VerticalBarChart from "@/components/vertical-bar-chart/Main.vue";
import LineChart from "@/components/line-chart/Main.vue";
import { api } from "@/utils/api-config";
import { gpf } from "@/utils/global-functions";
import { loadingScreen } from "@/utils/loading-screen";

export default defineComponent({
  components: {
    VerticalBarChart,
    LineChart,
  },
  setup() {
    const dataAD = reactive({
      listAD: [] as any,
      dataRC: {} as any,
      dataRV: {} as any,
    })

    // get data widget dashboard
    const getDataAD = async () => {
      const url = api.adminDashboard;
      const hitApi = await api.hitApiGet(url);
      const rs = hitApi.data.response;
      dataAD.listAD = rs.map((e: any) => {
        e.value = gpf.ctc(e.value);
        e.percent = "4/5";
        e.color = `${e.color.split("-")[1]}-${e.color.split("-")[2]}`;
        return e;
      });
    };

    // get data grafik pendaftaran chart
    const getDataRC = async () => {
      const url = api.adminRegisterChart;
      const hitApi = await api.hitApiGet(url);
      const rs = hitApi.data.response;
      dataAD.dataRC = rs;
    };

    // get data grafik revenue chart
    const getDataRV = async () => {
      const url = api.adminRevenueChart;
      const hitApi = await api.hitApiGet(url);
      const rs = hitApi.data.response;
      dataAD.dataRV = rs;
    };

    const getData = async () => {
      loadingScreen().show();

      await getDataAD();
      await getDataRC();
      await getDataRV();

      loadingScreen().hide();
    };
    getData();

    return {
      dataAD,
      getData,
    };
  },
  data() {
    return {
      listSK: sk,
      listTB: tahapanBelajar,
      listTP: tryoutPemantapan,
      listDA: listTopDA,
    }
  }
});
