type tth = {
  value: string;
};

const sk: Array<tth> = [
  {
    value:
      "Memperoleh Total Nilai Try Out Pemantapan CAT Pertama sebesar 390 (Melewati Nilai Ambang Batas TWK, TIU & TKP)",
  },
  {
    value:
      "Memperoleh Total Nilai Try Out Pemantapan CAT Kedua 430 (Melewati Nilai Ambang Batas TWK, TIU & TKP)",
  },
  {
    value:
      "Jika TIDAK mengikuti Try Out Pemantapan CAT maka Garansi Uang Kembali dinyatakan BATAL",
  },
  {
    value:
      "<b>Nilai Ambang Batas</b><ul><li>Tes Wawasan Kebangsaan (TWK) = 75</li><li>Tes Intelegensia Umum (TIU) = 80</li><li>Tes Karakteristik Pribadi (TKP) = 143</li></ul>",
  },
];

const tahapanBelajar: Array<tth> = [
  {
    value:
      "Bergabunglah di channel dan group telegram yang sudah kami siapkan tombol gabungnya di menu dashboard.",
  },
  {
    value:
      "Download materi yang sudah kami siapkan di menu materi, setelah download bacalah dengan telitibserta pahami dan hapal lah. Pelajari pula strategi jawabnya yang ada pada materi.",
  },
  {
    value:
      "Lakukan tanya jawab dengan tentor di menu dashboard pada tombol kelas belajar mengajar, yang akan membantu kamu menjawab persoapan yang kamubhadapi dalam menjawab soal CPNS.",
  },
  {
    value:
      "Jika Anda sudah PD (Percaya Diri) silahkan mengikuti Paket CAT SKD, untuk melatih dan mengukur kemampuan Anda dalam menjawab soal TWK, TIU dan TKP. kami sudah menyediakan 20 Paket soal SKD untuk Anda kerjakan.",
  },
  {
    value:
      "Ikuti Paket Pemantapan 1 dan 2 sebagai syarat garansi dari kami adhigama.education. Untuk mengikuti Paket Pemantapan 1 dan 2 yang syarat untuk mengikutinya telah kami tuliskan pada syarat dan ketentuan Paket Pemantapan.",
  },
  {
    value:
      "Latihlah diri Anda untuk mengerjakan soal SKB sesuai jurusan dan bidang yang Anda daftarkan pada situs resmi pendaftaran <a href='https://sscasn.bkn.go.id/' target='_blank'>https://sscasn.bkn.go.id/</a> , Saran kami kerjakan pada saat Anda sudah dinyatakan lulus SKD.",
  },
];

const tryoutPemantapan: Array<tth> = [
  {
    value:
      "Paket Tryout Pemantapan 1 dapat dilakukan jika telah mengikuti dan menyelesaikan tryout Paket SKD 1 - Paket SKD 10",
  },
  {
    value:
      "Paket Tryout Pemantapan 2 dapat dilakukan jika telah mengikuti dan menyelesaikan tryout Paket SKD 11 - Paket SKD 20",
  },
  {
    value:
      "Paket Tryout SKD dan SKB dapat di ulang setiap 7 hari dan Nilai tryout akan di reset",
  },
  {
    value: "Paket Tryout Pemantapan tidak dapat di ulang",
  },
  {
    value:
      "Mohon tryout menggunakan Laptop / Komputer tidak menggunakan Smartphone dan gunakan browser Google Chrome. <a href='https://www.google.com/chrome/' target='_blank'>Download di sini</a>",
  },
];

type topDA = {
  value: string;
  desc: string;
  icon: string;
  percent: string;
  color: string;
};

const listTopDA: Array<topDA> = [
  {
    value: "630.367.530",
    desc: "Omzet Penjualan",
    icon: "CreditCardIcon",
    percent: "3/5",
    color: "purple-500",
  },
  {
    value: "137.328.247",
    desc: "Sisa Saldo",
    icon: "MessageSquareIcon",
    percent: "2/5",
    color: "yellow-300",
  },
  {
    value: "165.327.383",
    desc: "Expense",
    icon: "Maximize2Icon",
    percent: "2/5",
    color: "green-500",
  },
  {
    value: "82.621.340",
    desc: "Net Profit",
    icon: "TagIcon",
    percent: "2/5",
    color: "blue-500",
  },
  {
    value: "1.615",
    desc: "Register Platinum",
    icon: "UserIcon",
    percent: "2/5",
    color: "red-400",
  },
  {
    value: "1.004",
    desc: "Register Premium",
    icon: "UserIcon",
    percent: "2/5",
    color: "red-400",
  },
  {
    value: "1.979",
    desc: "Register Free",
    icon: "UserIcon",
    percent: "2/5",
    color: "red-400",
  },
  {
    value: "1.313",
    desc: "Jumlah Transfer",
    icon: "UserIcon",
    percent: "2/5",
    color: "red-400",
  },
];

export { sk, tahapanBelajar, tryoutPemantapan, listTopDA };
